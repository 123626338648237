.profile {
  display: flex;
  flex-direction: column;
}

.profileTop {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profileBottom {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}

.profileCover {
  height: 320px;
  position: relative;
  width: 100%;
}

.profileCoverImg {
  height: 250px;
  object-fit: cover;
  width: 100%;
}

.profilePicture {
  border-radius: 50%;
  border: 3px solid white;
  height: 150px;
  left: 0;
  margin: auto;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 150px;
  width: 150px;
}

.profilePictureEditButton {
  align-items: center;
  background-color: #ffa000;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  left: 52%;
  position: absolute;
  right: auto;
  top: 250px;
  width: 30px;
}

.coverPhotoEditButton {
  align-items: center;
  background-color: #ffa000;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  left: 90%;
  position: absolute;
  right: auto;
  top: 200px;
  width: 30px;
}

.profileBottomInfo {
  padding: 20px;
  flex: 3;
}

.profileBottomLeft {
  display: none;
  flex: 3;
}

.profileInfoName {
  font-style: 24px;
}

.profileInfoDesc {
  font-weight: 300px;
}

.profileBottomFeed {
  display: flex;
  flex: 6;
  justify-content: center;
  width: 100%;
}

@media (min-width: 768px) {
  .profileBottom {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .coverPhotoEditButton {
    left: 97%;
  }

  .profileBottomLeft {
    display: flex;
    flex: 3;
  }
}
