.post {
  -webkit-box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(192, 192, 192);
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  display: inline-block;
  margin-bottom: 20px;
  max-width: 350px;
}

.postTop {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.postTopLeft {
  display: flex;
  justify-content: center;
  align-items: center;
}

.postProfileImg {
  border-radius: 50%;
  height: 32px;
  object-fit: cover;
  width: 32px;
}

.postUsername {
  color: black;
  font-style: 15px;
  font-weight: 500;
  margin: 0px 10px;
  display: block;
  min-width: 100px;
}

.postDate {
  cursor: pointer;
  font-size: 12px;
}

.postText {
  padding: 0 25px;
  display: block;
  max-width: 300px;
}

.postCommentText {
  display: block;
  max-width: 150px;
}

.postImg {
  margin-top: 20px;
  max-height: 500px;
  object-fit: cover;
  width: 100%;
}

.postBottom {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.postBottomLeft {
  align-items: center;
  display: flex;
}

.likeIcon {
  cursor: pointer;
  margin-right: 5px;
}

.welcomeButton:hover {
  background-color: red;
}

.welcomeButton:active {
  background-color: red;
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  transform: translateY(4px);
}

.commentIcon {
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.welcomeButton:active {
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  transform: translateY(4px);
}

.postLikeCounter {
  font-size: 15px;
}

.commentInputForm {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.postCommentText {
  cursor: pointer;
  font-size: 15px;
}

.commentInput {
  border: none;
  width: 80%;
}

.commentInput:focus {
  outline: none;
}

.commentHr {
  margin: 0 25px;
}

.commentError {
  background-color: rgb(255, 81, 0);
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 50px;
  margin: 0 20px 20px 20px;
  color: rgb(255, 255, 255);
  padding: 0 20px;
  font-size: 15px;
}

.commentsTitle {
  margin-bottom: 10px;
}

@media (max-width: 400px) {
  .postTopLeft {
  }

  .postText {
    max-width: 500px;
  }

  .postCommentText {
    max-width: 230px;
    margin: 10px 0;
  }
}

@media (min-width: 510px) {
  .postText {
    max-width: 500px;
  }

  .postCommentText {
    max-width: 260px;
  }
}

@media (min-width: 650px) {
  .postText {
    max-width: 500px;
  }

  .postCommentText {
    max-width: 400px;
  }
}
