.comment {
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.commentBody {
  display: flex;
}

.commentHr {
  background-color: rgb(192, 192, 192);
}

.postComment {
  margin: 10px 0;
}

.commentDelete {
  font-size: 5px;
  cursor: pointer;
  color: brown;
}
