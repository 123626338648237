.profileFeed {
  max-width: 600px;
  padding: 20px;
  width: 100%;
}

.profileFeedShare {
  margin-bottom: 20px;
  max-width: 600px;
  min-width: 300px;
  width: 100%;
}

.profileFeedPosts {
  align-items: center;
  column-count: 1;
  column-gap: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 1000px;
}

.profileFeedHr {
  margin: 30px 20px;
  opacity: 0.5;
}

.profileFeedOptions {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 30px;
}

.profileFeedOptionsFollowing {
  color: rgb(129, 129, 129);
  font-weight: 600;
}

.profileFeedOptionsAll {
  color: orange;
  font-weight: 600;
}

.post {
  max-width: 600px;
  width: 100%;
}
