.topbarContainer {
  -webkit-box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.21);
  align-items: center;
  background-color: #e7971d;
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.21);
  display: flex;
  height: 60px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
}

.topbarPlaceHolder {
  z-index: -5;
  position: absolute;
}

.logo {
  color: #ffffff;
  cursor: pointer;
  font-size: 30px;
  font-weight: 600;
}

.topbarRightMenuItemNotification {
  max-width: 300px;
}

.icon {
  font-size: 20px !important;
  margin-left: 10px;
}

.topbarRight {
  width: 250px;
}

.topbarCenter {
  margin-left: 20px;
  max-width: 350px;
  width: 250px;
}

.searchInput {
  background-color: #ffffff;
  border: none;
  width: 100%;
}

.searchInput:focus {
  outline: none;
}

.topbarRight {
  align-items: center;
  color: #ffffff;
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}

.topbarIcons {
  display: flex;
}

.topbarIconsItem {
  cursor: pointer;
  margin-right: 15px;
  position: relative;
}

.topbarIconBadge {
  align-items: center;
  background-color: #c44721;
  border-radius: 50%;
  bottom: -5px;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  position: absolute;
  top: 10px;
  width: 15px;
}

.topbarImage {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

/* menu styling */
.topbarRightMenu {
  display: none;
}

.topbarRightMenu li {
  list-style: none;
}

.topbarRightHamburgerIcon {
  display: block;
}

.searchBar {
  display: none;
}

.topbarRightHamburgerMenu {
  align-items: center;
  background-color: #fefdfc;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  top: 60px;
  width: 200px;
  z-index: 3;
}

.topbarRightHamburgerItem {
  color: #555555;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
}

.topbarRightHamburgerOverlay {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  position: absolute;
  right: 0;
  top: 60px;
  width: 100vw;
}

@media (min-width: 768px) {
  .searchBar {
    align-items: center;
    background-color: #ffffff;
    border-radius: 30px;
    border: 1px solid rgb(192, 192, 192);
    display: flex;
    height: 30px;
    width: 100%;
  }

  .topbarRightHamburgerIcon {
    display: none;
  }

  .topbarRightMenu {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
}