.welcomeWrapper {
  background: no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}

.welcomeLogin {
  align-items: center;
  display: flex;
  height: 450px;
  justify-content: center;
}

.welcomeInput {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: center;
  width: 500px;
}

.welcomeTitle {
  color: #fefdfc;
  font-size: 50px;
  font-weight: 800;
  margin: 15px;
  text-align: center;
}

.welcomeDesc {
  color: #fefdfc;
  margin-bottom: 25px;
}

.welcomeButtons {
  align-items: space-between;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.welcomeButton {
  align-self: center;
  background-color: #fefdfc;
  border-radius: 10px;
  border: none;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 15px;
  height: 40px;
  margin: 0 10px;
  width: 100px;
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
}

.welcomeButton:hover {
  background-color: orange;
}

.welcomeButton:active {
  background-color: orange;
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  transform: translateY(4px);
}

.welcomeGuestSignIn {
  color: #fefdfc;
  cursor: pointer;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .welcomeLogin {
    height: 550px;
  }
}

@media (max-height: 768px) {
  .welcomeLogin {
    height: 400px;
  }
}
