.singlePostWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
}

.commentsSection {
  max-width: 600px;
  width: 100%;
}

.commentsWrapper {
  -webkit-box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(192, 192, 192);
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  padding: 25px;
}

.commentsTitle {
  font-size: 16px;
  font-weight: 600;
}

.comments {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.commentsText {
  margin-top: 20px;
}
