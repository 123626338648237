.topbarPopover {
  width: 200px;
}

.TopbarPopoverHr {
  margin: 15px 0;
  min-width: 100px;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  min-width: 150px;
}
