.searchWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.searchBarFull {
  align-items: center;
  background-color: #ffffff;
  border-radius: 30px;
  border: 1px solid rgb(192, 192, 192);
  display: flex;
  height: 30px;
  max-width: 600px;
  width: 100%;
}

.searchInput {
  background-color: #ffffff;
  border: none;
  width: 100%;
}

.searchResults {
  -webkit-box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(192, 192, 192);
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 15px;
  max-width: 600px;
  padding: 25px 0;
  width: 100%;
  flex-wrap: wrap;
}

.searchInput:focus {
  outline: none;
}
