/* .profileInfo {
  display: flex;
  justify-content: flex-start;
} */

.profileInfoName {
  font-size: 20px;
}

/* .profileInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
} */

.profileInfoInfo {
  margin-bottom: 30px;
}

.profileInfoInfoItem {
  margin-bottom: 10px;
}

.profileInfoInfoKey {
  font-weight: 500;
  margin-right: 15px;
  color: rgb(70, 70, 70);
  margin-bottom: 15px;
}

.profileInfoInfoValue {
  font-weight: 300;
}

.profileInfoFollowings {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}

.profileInfoFollowButton {
  margin-bottom: 30px;
  width: 150px;
  border: none;
  background-color: rgb(116, 175, 28);
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.profileInfoFollowButton:hover {
  background-color: rgb(127, 195, 26);
}

.profileInfoFollowButton:active {
  background-color: rgb(127, 195, 26);
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  transform: translateY(4px);
}

.profileInfoFollowButton:focus {
  outline: none;
}

.profileInfoSections {
  display: none;
  flex-direction: row;
}
.profileInfoSection {
  margin-bottom: 20px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
}

.profileInfo {
  justify-content: center;
  align-items: center;
}
.profileInfoWrapper {
  padding: 0;
}

.profileInfoTitle {
  display: none;
}

.followError {
  background-color: rgb(255, 81, 0);
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 20px;
  color: rgb(255, 255, 255);
  padding: 0 20px;
}

@media (min-width: 768px) {
  .profileInfoSections {
    display: flex;
    flex-direction: column;
  }

  .profileInfoTitle {
    font-size: 18px;
    font-weight: 500;
    margin: 25px 0;
    display: block;
  }
}
