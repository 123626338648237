._it4vx {
  height: 3px;
  background: #27c4f5 -webkit-gradient(linear, left top, right top, from(#27c4f5), color-stop(#a307ba), color-stop(#fd8d32), color-stop(#70c050), to(#27c4f5));
  background: #27c4f5 -webkit-linear-gradient(left, #27c4f5, #a307ba, #fd8d32, #70c050, #27c4f5);
  background: #27c4f5
    linear-gradient(to right, #27c4f5, #a307ba, #fd8d32, #70c050, #27c4f5);
  background-size: 500%;
  -webkit-animation: 2s linear infinite LoadingBarProgress,
    0.001s ease-out LoadingBarEnter;
  animation: 2s linear infinite LoadingBarProgress,
    0.001s ease-out LoadingBarEnter;
  -webkit-transform-origin: left;
  transform-origin: left;
  width: 100%;
}
._72fik {
  left: 0;
  position: fixed;
  right: 0;
  top: 60px;
  z-index: 12;
}
@keyframes LoadingBarProgress {
  0% {
    background-position: 0% 0;
  }
  to {
    background-position: 125% 0;
  }
}
@keyframes LoadingBarEnter {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
