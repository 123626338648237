.notification,
.notificationNew {
  margin: 10px;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px;
  max-width: 200px;
}

.notification {
}

.notificationNew {
  background-color: rgb(185, 239, 250);
}

.topbarIconBadgeHide {
  display: none;
}

.css-17ffvgn-MuiTypography-root {
  max-width: 300px;
}
