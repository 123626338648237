.login {
  align-items: center;
  background: no-repeat center center fixed;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
}

.loginWrapper {
  align-items: center;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;
  max-height: 800x;
  max-width: 600px;
  min-height: 550px;
  min-width: 250px;
  padding: 30px;
  width: 40%;
}

.loginTitle,
.loginItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginTitle {
  padding-bottom: 20px;
}

.loginLogo {
  color: orange;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 10px;
}

.loginError {
  background-color: rgb(255, 81, 0);
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 20px;
  color: rgb(255, 255, 255);
  padding: 0 20px;
}

.loginDesc {
  font-style: 24px;
  text-align: center;
}

.loginBox {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 20px;
}

.loginInput {
  border-radius: 10px;
  border: 1px solid rgb(0, 0, 0);
  font-size: 18px;
  height: 50px;
  margin-bottom: 15px;
  padding-left: 20px;
}

.loginButton,
.loginRegisterButton {
  align-self: center;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  color: aliceblue;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  height: 50px;
  margin-top: 15px;
  width: 100%;
}

.loginButton:hover {
  background-color: rgb(255, 181, 45);
}

.loginRegisterButton:hover {
  background-color: rgb(248, 241, 241);
}

.loginButton:active,
.loginRegisterButton:active {
  background-color: #ffb835;
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  transform: translateY(4px);
}

.loginInput:focus {
  outline: none;
}

.loginForgot {
  color: orange;
  cursor: pointer;
  margin-bottom: 25px;
  text-align: center;
}

.loginButton {
  background-color: orange;
  border: none;
}

.loginRegisterButton {
  border-width: 1px;
  color: orange;
}
