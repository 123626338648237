.share {
  -webkit-box-shadow: 5px 6px 14px -8px rgba(0, 0, 0, 0.52);
  border-radius: 10px;
  border: 1px solid rgb(192, 192, 192);
  box-shadow: 5px 6px 14px -8px rgba(0, 0, 0, 0.52);
  width: 100%;
}

.shareWrapper {
  padding: 25px;
}

.shareProfileImg {
  border-radius: 50%;
  height: 50px;
  margin-right: 10px;
  object-fit: cover;
  width: 50px;
}

.shareTop {
  align-items: center;
  display: flex;
}

.shareInput {
  border: none;
  width: 80%;
}

.shareInput:focus {
  outline: none;
}

.shareHr {
  background-color: rgb(192, 192, 192);
  margin: 20px 0;
}

.shareBottom {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.shareOptions {
  display: flex;
}

.shareOption {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-right: 15px;
}

.shareIcon {
  font-size: 18px;
  margin-right: 5px;
}

.shareOptionText {
  font-size: 14px;
  font-weight: 500;
}

.shareButton {
  background-color: #e7971d;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 500;
  padding: 5px;
  width: 75px;
}

.shareButton:hover {
  background-color: #ffb835;
}

.shareButton:active {
  background-color: #ffb835;
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  transform: translateY(4px);
}

.shareImgContainer {
  padding: 0 20px 10px 20px;
  position: relative;
}

.shareImg {
  max-height: 400px;
  object-fit: cover;
  width: 100%;
}

.shareDiscard {
  cursor: pointer;
  opacity: 0.7;
  position: absolute;
  right: 20px;
  top: 0;
}

.shareTags {
  margin: 0 0 0 30px;
  padding-left: 10px;
  padding: 0 10px;
  text-decoration: none;
  padding: 3px;
  border-radius: 50px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  background-color: #f27d7d;
  border: none;
}

.shareError {
  background-color: rgb(255, 81, 0);
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 20px;
  color: rgb(255, 255, 255);
  padding: 0 20px;
}
