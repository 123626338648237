.feedWrapper {
  padding: 25px;
}

.feedShareWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.feedShare {
  max-width: 600px;
  min-width: 300px;
  width: 100%;
}

.feedPosts {
  align-items: center;
  column-count: 1;
  column-gap: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 1000px;
}

.feedHr {
  margin: 30px 20px;
  opacity: 0.5;
}

.feedOptions {
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 30px;
  position: relative;
}

.feedOptionsGrey {
  color: rgb(129, 129, 129);
  font-weight: 400;
}

.feedOptionsOrange {
  color: orange;
  font-weight: 600;
}

.feedNoPosts {
  display: flex;
  justify-content: center;
}

.post {
  max-width: 100%;
  width: 100%;
}

.postWrapper {
  max-width: 100%;
}

@media (min-width: 768px) {
  .feedPosts {
    column-count: 3;
    column-gap: 15px;
    margin: auto;
    max-width: 1000px;
    display: block;
  }

  .feedPostsLoading {
    max-height: 700px;
  }

  .post {
    max-width: 600px;
    width: 100%;
  }
}