.noPosts {
  -webkit-box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(192, 192, 192);
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.34);
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: space-between;
  margin: 20px 0;
  max-width: 600px;
  width: 100%;
}

.noPostsTitle {
  color: rgb(70, 70, 70);
  font-weight: 600;
  padding-top: 25px;
  max-width: 550px;
}

.noPostsImg {
  height: 200px;
  padding-bottom: 25px;
  width: 200px;
}
